import React from 'react'
import Navbar from './Navbar'
import AimatedText from './AnimatedText'
import {FaUserCircle} from "react-icons/fa"
import {LiaFlagUsaSolid} from 'react-icons/lia'
import {AiOutlineMail} from "react-icons/ai"
import {TbMessage2Bolt} from "react-icons/tb"
import { useForm } from "react-hook-form";
import Footer from './Footer'
import { useEffect, useState } from "react"
import { SyncLoader } from 'react-spinners'

const Contact = () => {

  const {
    register,
    trigger,
    formState: { errors },
  } = useForm();

  const onSubmit = async (e) => {
    console.log("~ e", e);
    const isValid = await trigger();
    if (!isValid) {
      e.preventDefault();
    }
  };

  const [isLoading, setIsLoading] = useState(true)


  useEffect(()=> {
      setIsLoading(true)
      setTimeout(()=> {
         setIsLoading(false)

      },5000)
 }, [])
  
  return (
    <div>
    {isLoading ? (
        
        
        <div className='flex justify-center mx-auto  bg__Load bg-black/10 backdrop-blur-lg h-[100vh] pt-[75%] xl:pt-[25%] 2xl:pt-[25%] lg:pt-[25%] sm:pt-[50%] md:pt-[50%]'>
        {/* <ScaleLoader color={"#030d26"} loading={isLoading}  size={100} className='  '/> */}
        <SyncLoader color={"#1C7FBB"}  loading={isLoading} size={20} className=""/>
        </div>
        
    
  ) : ( <div className=''>
    <>
     
    
    <Navbar/>

    <div className=" pt-[20%] xl:pt-[10%] lg:pt-[10%]">
        <AimatedText text="Contact Us" className='text-[2rem] xl:text-[3rem] text-[#1C7FBB] text-center'/>



        <div className="container mx-auto p-4">
      <form className="max-w-lg mx-auto" 
      action='https://formsubmit.co/3fb914bfc9bd768057c667fa20bf2471'
      onSubmit={onSubmit}
      method='POST'>
        <div className="mb-4 relative">
           <FaUserCircle className="w-5 h-5 absolute left-3 top-3 text-gray-400 " /> 
          <input
            type="text"
            id="firstName"
            name="firstName"
            placeholder="Name"
            className="border border-gray-300 rounded pl-10 pr-3 py-2 w-full focus:outline-none focus:ring-2 text-black focus:ring-[#1C7FBB]"
            {...register("Name", {
              required: true,
              maxLength: 100,
            })}/>
        </div>
        {errors.email && (
                  <div className='flex  justify-center mt-2'>
                  <p className="text-red mt-1 bg-[red] rounded-md pl-3 pr-3 text-white mb-2">
                    {errors.email.type === "required" && "Your Name Is Required."}
                    {/* {errors.email.type === "pattern" && "Invalid email address."} */}
                  </p>
                  </div>
                    )}
        <div className="mb-4 relative">
           <LiaFlagUsaSolid className="w-5 h-5 absolute left-3 top-3 text-gray-400" /> 
          <input
            type="text"
            id="lastName"
            name="lastName"
            placeholder="Country"
            className="border border-gray-300 rounded pl-10 pr-3 py-2 w-full focus:outline-none focus:ring-2 text-black focus:ring-[#1C7FBB]"
            {...register("Country", {
              required: true,
              maxLength: 2000,
            })}/>
        </div>
        {errors.email && (
                  <div className='flex  justify-center mt-2'>
                  <p className="text-red mt-1 bg-[red] rounded-md pl-3 pr-3 mb-2 text-white ">
                    {errors.email.type === "required" && "Your Country Is Required."}
                    {/* {errors.email.type === "pattern" && "Invalid email address."} */}
                  </p>
                  </div>
                    )}
        <div className="mb-4 relative">
           <AiOutlineMail className="w-5 h-5 absolute left-3 top-3 text-gray-400" /> 
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            className="border border-gray-300 rounded pl-10 pr-3 py-2 w-full focus:outline-none focus:ring-2 text-black focus:ring-[#1C7FBB]"
            {...register("email", {
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            })}/>
        </div>
        {errors.email && (
                  <div className='flex  justify-center mt-2'>
                  <p className="text-red mt-1 bg-[red] rounded-md pl-3 pr-3 mb-2 text-white ">
                    {errors.email.type === "required" && "Your Email Is Required."}
                    {errors.email.type === "pattern" && "Invalid email address."}
                  </p>
                  </div>
                    )}

      <select id="my-select"
      {...register("selection", {
        required: true,
        
      })} className='border border-gray-300 rounded pl-2 pr-5 py-2 w-full focus:outline-none focus:ring-2 text-black focus:ring-[#1C7FBB] mb-5'>
        <option value="" className='text-[gray]'>Select</option>
        <option value="Restore Lost Crypto">Restore Lost Crypto</option>
        <option value="Romance Scam Recovery">Romance Scam Recovery</option>
        <option value="Bitcoin Recovery">Bitcoin Recovery</option>
        <option value="Wallet Tracking">Wallet Tracking</option>
        <option value="Spy">Spying</option>
        <option value="Social Media Hack">Social Media hack/Recovery</option>
        <option value="Icloud Unlock">Icloud Unlock</option>
        <option value="Account Activation">Account Activation</option>
        <option value="BlackMail/Extortion">BlackMail/Extortion</option>
        <option value="Restore Money From Scammers">Restore Money From Scammers</option>
      </select>
                    
        
        <div className="mb-4 relative ">
         <TbMessage2Bolt className="w-5 h-5 absolute left-3 top-3 text-gray-400" /> 
          <textarea
            id="situation"
            name="situation"
            placeholder="Describe Your Situation"
            className="border border-gray-300 rounded pl-10 pr-3 py-2 w-full resize-none focus:outline-none focus:ring-2 text-black focus:ring-[#1C7FBB]"
            rows="4"
            {...register("Message", {
              required: true,
              maxLength: 2000,
            })}></textarea>

{errors.email && (
                  <div className='flex  justify-center mt-2'>
                  <p className="text-red mt-1 bg-[red] rounded-md pl-3 pr-3 text-white ">
                    {errors.email.type === "required" && "Message is Required"}
                    {/* {errors.email.type === "pattern" && "Invalid email address."} */}
                  </p>
                  </div>
                    )}
        </div>
        
        <button
          type="submit"
          className="bg-[#1C7FBB] text-white px-4 py-2 rounded"
        >
          Submit
        </button>
      </form>
        </div>

    <div className='mt-[10%]'>
    <Footer/>
    
    </div>
    
    </div>
    
    </>
    </div> )}
    </div>
  )
}

export default Contact