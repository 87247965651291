import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import AimatedText from './AnimatedText'
import { Link } from 'react-router-dom'
import Footer from './Footer'
import { SyncLoader } from 'react-spinners'
// import CryptoSwipe from './CryptoSwipe'
import { motion } from 'framer-motion'
import Swiper from "./Swiper"

const RomanceScamRestore = () => {
    const [isLoading, setIsLoading] = useState(true)


    useEffect(()=> {
        setIsLoading(true)
        setTimeout(()=> {
           setIsLoading(false)

        },5000)
   }, [])
  return (
    <>
    <div>
    {isLoading ? (
        
        
        <div className='flex justify-center mx-auto  bg__Load bg-black/10 backdrop-blur-lg h-[100vh] pt-[75%] xl:pt-[25%] 2xl:pt-[25%] lg:pt-[25%] sm:pt-[50%] md:pt-[50%]'>
        {/* <ScaleLoader color={"#030d26"} loading={isLoading}  size={100} className='  '/> */}
        <SyncLoader color={"#1C7FBB"}  loading={isLoading} size={20} className=""/>
        </div>
        
    
  ) : ( <div className=''>
        <Navbar/>
        <div className='mx-auto  overflow-hidden md:max-w-7xl'>
            <div className=" pt-[20%] xl:pt-[10%] lg:pt-[10%]">
                <AimatedText text="Romance Scam Recovery" className='text-[2rem] xl:text-[3rem] text-[#1C7FBB] text-center'/>
            </div>
            <div className="flex justify-center pt-4">
              <Link to="/"><p className='font-semibold'>Home <span className="text-[#0d0d35]">/ Service</span></p></Link>
            </div>
        </div>

        <div className='mx-auto  overflow-hidden md:max-w-7xl'>
            <div className='grid ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-10'>
                <div className='bg-[reen] h-fit overflow-hidden'>
                    <motion.p className='pt-3 pl-2 pr-1' 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.2, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                    Within the realm of the lamentable web of dating scams, where lost funds, properties, loans, and ill-gotten gains 
                    masquerade as heart-wrenching tales of romance, the pursuit of restitution takes center stage. In this intricate 
                    landscape, where deceit thrives and trust is mercilessly exploited, we emerge as a beacon of hope, steadfast in 
                    our commitment to recover what has been lost.
                    Our expertise extends to the intricate art of reclaiming lost funds ensnared within the clutches of dating scams. 
                    From meticulously restoring financial losses to recovering lost properties and rescuing loans ensnared by the sinister 
                    allure of romance scams, we navigate the treacherous waters with consummate skill. Our prowess extends further, 
                    encompassing the retrieval of funds trapped within the webs spun by fake romance accounts, thereby ensuring that 
                    justice triumphs over deception.</motion.p> <br /> <br />

                    <motion.p 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.3, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>Place your trust in us, for we possess the unwavering resolve to deliver reparation. With an unyielding dedication 
                     to our clients, we unveil an arsenal of strategies and resources, forging a path towards the recovery of what is rightfully 
                     yours. Together, we shall unravel the intricate threads of deceit, restoring both your financial well-being and the faith 
                     that may have been shattered.
                    </motion.p>

                    <div className='flex justify-center mb-[10%] pl-5 pt-10'>
                      <Link to="/Contact"><button className='mt-5 Con__btn'>Contact Us</button></Link>  
                    </div>
                </div>

                <div className='bg-[reen] h-fit flex justify-center'>
                        <div >
                            <img src="https://static.vecteezy.com/system/resources/previews/016/108/228/large_2x/try-to-fix-broken-heart-valentines-day-concept-end-to-relationship-or-marriage-break-up-and-separation-split-of-couple-cracked-love-free-photo.JPG" alt="" />
                        </div>
                </div>
            </div>

           
        </div>
            <div className='bg-[#E6F4FC] pb-10 pt-5 pl-3 pr-3' id='Review'>
                <div className='mx-auto overflow-hidden md:max-w-6xl'>
                    <Swiper/>   
                </div>
            </div>

        
        <div className='mt-0'>
        <Footer/>
        </div>
        </div> )}
    </div>
    
    </>
  )
}

export default RomanceScamRestore;