import React, { useEffect, useRef, useState } from 'react'
import Navbar from './Navbar'
import { Link } from 'react-router-dom'
import AimatedText from './AnimatedText'
import { useInView, useMotionValue, useSpring } from 'framer-motion'
import { motion } from 'framer-motion'
import TrustSwipe from './TrustSwipe'
import Footer from "./Footer"
import Swiper from "./Swiper"
import { SyncLoader } from 'react-spinners'


const About = () => {
    const AnimatedNumbers = ({value}) => {
        const ref = useRef(null);
        const motionValue = useMotionValue(0);
        const springValue = useSpring(motionValue, {duration: 6000})
        const isInview = useInView(ref);
    
        
    
    
    
        useEffect(() => {
          if(isInview){
            motionValue.set(value);
          }
        }, [isInview, value, motionValue])
    
        useEffect(() => {
            springValue.on("change", (latest) => {
                if(ref.current  && latest.toFixed(0) <= value){
                    ref.current.textContent = latest.toFixed(0);
                }
            })
         
        }, [springValue, value])
        
    
        return <span ref={ref}></span>
    }

    const [isLoading, setIsLoading] = useState(true)


    useEffect(()=> {
        setIsLoading(true)
        setTimeout(()=> {
           setIsLoading(false)

        },5000)
   }, [])
  return (
    <>
     <div>
    {isLoading ? (
        
        
        <div className='flex justify-center mx-auto  bg__Load bg-black/10 backdrop-blur-lg h-[100vh] pt-[75%] xl:pt-[25%] 2xl:pt-[25%] lg:pt-[25%] sm:pt-[50%] md:pt-[50%]'>
        {/* <ScaleLoader color={"#030d26"} loading={isLoading}  size={100} className='  '/> */}
        <SyncLoader color={"#1C7FBB"}  loading={isLoading} size={20} className=""/>
        </div>
        
    
  ) : ( <div className=''>
       <Navbar/>
       <div className='mx-auto  overflow-hidden md:max-w-6xl'>
            <div className=" pt-[20%] xl:pt-[10%] lg:pt-[10%]">
                <AimatedText text="About Us" className='text-[2rem] xl:text-[3rem] text-[#1C7FBB] text-center'/>
            </div>
            <div className="flex justify-center pt-4">
              <Link to="/"><p className='font-semibold'>Home <span className="text-[#0d0d35]">/ About</span></p></Link>
            </div>

            <div className='flex justify-center'>
                <p className="font-semibold text-center pt-5">Engage with a team of industry pioneers, armed with an unparalleled understanding of the nuances 
                that encompass recovery and bypassing. Witness firsthand the transformative power of our cutting-edge technologies, which 
                propel us beyond conventional limitations and empower us to restore what was once deemed irretrievable.</p>
            </div>
        </div>

        <div className='mx-auto  overflow-hidden md:max-w-7xl'>
            <div className="grid  grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-1 mt-[5%]">
                <div className="bg-[reen] h-fit">
                    <div className='pl-3 pr-2'>
                        <img src="https://img.freepik.com/premium-photo/business-team-working-together-office-with-laptop_23-2149206535.jpg" alt="" />
                    </div>
                    <div className='flex justify-center pt-10 mb-[10%] pl-5'>
                      <Link to="/Contact"><button className='mt-5 Con__btn'>Contact Us</button></Link>
                    </div>
                </div>

                <div className="bg-[reen] h-fit pb-[10%]">

                    <div className='pt-10 lg:pt-5 xl:pt-0'>
                        <p className='pl-5 text-[1.3rem] font-semibold text-[#1C7FBB]'>Designed by investigators for investigators.</p>
                    </div>

                    <div className=''>
                        <p className='pl-5'>
                        At our distinguished recovery firm, we embark on a relentless mission to bring justice to those who have fallen victim 
                        to scammers, restore stolen funds to their rightful owners, and resurrect disabled social media accounts. Our 
                        unwavering commitment to our clients is what sets us apart. <br/>
                        Furthermore, our expertise extends to the realm of social media, where we possess the prowess to resurrect disabled 
                        accounts and restore access to those who have been unjustly locked out. We understand the significance of one's digital 
                        presence and strive to empower individuals to reclaim their online identities. <br/> <br />
                        Through our sophisticated bypassing techniques, we transcend barriers that obstruct access, enabling our clients to 
                        regain control over their accounts with swift precision and unwavering accuracy. Our prowess in bypassing security 
                        measures is matched only by our commitment to upholding ethical standards, ensuring that our clients' interests 
                        remain safeguarded throughout the process. <br /> <br />
                        Through our sophisticated bypassing techniques, we transcend barriers that obstruct access, enabling our clients to 
                        regain control over their accounts with swift precision and unwavering accuracy. Our prowess in bypassing security 
                        measures is matched only by our commitment to upholding ethical standards, ensuring that our clients' interests remain 
                        safeguarded throughout the process. <br/>
                        Welcome to our realm of expertise, where resilience meets restoration, and justice prevails.
                        </p>
                    </div>
                </div>

                
            </div>

            <div className=" mx-auto overflow-hidden md:max-w-6xl">
                     <div className="grid  grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 mt-[2%]">
                        <div className="bg-[8c0808] h-[20vh]">
                            <span className='flex justify-center  lg:text-4xl text-2xl  font-bold md:text-3xl sm:text-3xl xs:text-2xl 
                            pt-5 text-[#000]'>
                                        <AnimatedNumbers value={562} className=" !font-sans"/><span className="text-[#1C7FBB]">+</span>
                            </span>

                            <div className="flex justify-center ">
                                <p className="lg:text-2xl font-medium text-center text-[#1C7FBB]">Projects</p>
                            </div>
                        </div>
                         <div className="bg-[8c0808] h-[20vh]">
                            <span className='flex justify-center lg:text-4xl text-2xl  font-bold md:text-3xl sm:text-3xl xs:text-2xl 
                            pt-5 text-[#000]'>
                                        <AnimatedNumbers value={470} className=" !font-sans"/><span className="text-[#1C7FBB]">+</span>
                            </span>

                            <div className="flex justify-center ">
                                <p className="lg:text-2xl font-medium text-center text-[#1C7FBB]">Trusted By</p>
                            </div>
                        </div>
                         <div className="bg-[8c0808] h-[20vh]">
                            <span className='flex justify-center lg:text-4xl  text-2xl font-bold md:text-3xl sm:text-3xl xs:text-2xl 
                            pt-5 text-[#000]'>
                                        <AnimatedNumbers value={500} className=" !font-sans"/><span className="text-[#1C7FBB]">+</span>
                            </span>

                            <div className="flex justify-center ">
                                <p className="lg:text-2xl font-medium text-center text-[#1C7FBB]">Clients</p>
                            </div>
                        </div>
                    </div>
                </div>
        </div>

         <div className='bg-[#E6F4FC] h-fit'>
                <div className='mx-auto  overflow-hidden md:max-w-7xl'>
                    <div className='flex justify-center'>
                        <p className='text-[1.8rem] font-semibold pt-[5%]'>Scam Prevention</p>
                    </div>




                    <div className='grid  grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-[10%] mt-[2%]'>
                        <div className='bg-[reen] h-fit'>
                            <div className='flex justify-center'>
                                <img src="https://www.consumer.ftc.gov/sites/www.consumer.ftc.gov/files/pictures/sentinel-data-book-2017-snapshot.png" alt="" 
                                className='w-[70%] md:w-[50%] xl:w-[60%]'/>
                            </div>
                        </div>
                         <div className='bg-[reen] h-fit'>
                            <p className='pl-3 pr-1'>We help organizations predict and detect fraud risks. We review and investigate suspicions of fraud, and unethical 
                                behaviour by employees or third parties. An early response to reported fraudulent behaviour can reduce financial 
                                and reputational damage. Find out how to deal with such situations. <br />
                                Scams target people of all backgrounds, ages and income levels across the world. There's no one group of people who 
                                are more likely to become a victim of a scam, all of us may be vulnerable to a scam at some time. <br /> <br />
                                </p>

                            <p className='pl-3 pr-1'>
                            Scams succeed because they look like the real thing and catch you off guard when you’re not expecting it. Scammers 
                            are getting smarter and taking advantage of new technology, new products or services and major events to create 
                            believable stories that will convince you to give them your money or personal details.<br/>
                            Scams succeed because they look like the real thing and catch you off guard when you’re not expecting it. Scammers 
                            are getting smarter and taking advantage of new technology, new products or services and major events to create 
                            believable stories that will convince you to give them your money or personal details.
                            </p>
                        </div>
                    </div>
                </div>

            

        </div>
        <div className='mx-auto  overflow-hidden md:max-w-6xl'>
            <div className=" pt-[20%] xl:pt-[10%] lg:pt-[10%]">
                <AimatedText text="Our Company Values" className='text-[2rem] xl:text-[3rem] text-[black] text-center'/>
            </div>
            

            <div className='flex justify-center'>
                <p className="font-semibold text-center pt-5">We pride ourselves on being equal parts curious, honest, and passionate. 
                Every day, we’re focused on solving complex problems that make our world safer and lay a foundation for the cryptocurrency 
                economy to flourish.</p>
            </div>
        </div>

        <div className='mx-auto  overflow-hidden md:max-w-6xl'>
            <div className='grid  grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-2 pb-[10%] mt-[2%]'>
                  <div className='bg-[reen] h-fit'>
                  <motion.div className='flex pl-3 pt-5'
                             initial="hidden"
                             whileInView="visible"
                             viewport={{ once: true, amount: 0.5 }}
                             transition={{ delay: 0.5, duration: 0.5 }}              
                             variants={{
                             hidden: { opacity: 0, y: 50 },
                             visible: { opacity: 1, y: 0 },
                             }}>
                                <div className='pt-'>
                                    {/* <img src="check-markx.png" alt="" className='w-[64px]'/> */}
                                </div>
                                <div className='pt-10 flex'>
                                <i class="ri-check-line text-[1.3rem] text-[#1C7FBB]"></i>  
                                <p className='pl-2 text-[black] font-medium text-[1.2rem]'>Resolute Adaptability</p>
                                </div>
                                
                            </motion.div>
                            <motion.div className='pl-[5%] pr-2'
                             initial="hidden"
                             whileInView="visible"
                             viewport={{ once: true, amount: 0.5 }}
                             transition={{ delay: 0.5, duration: 0.5 }}              
                             variants={{
                             hidden: { opacity: 0, y: 50 },
                             visible: { opacity: 1, y: 0 },
                             }}>
                                <p className='text-[black]'>Have a clear point of view, but stay open to new information</p>
                    </motion.div>

                    <motion.div className='flex pl-3 pt-2'
                             initial="hidden"
                             whileInView="visible"
                             viewport={{ once: true, amount: 0.5 }}
                             transition={{ delay: 0.5, duration: 0.5 }}              
                             variants={{
                             hidden: { opacity: 0, y: 50 },
                             visible: { opacity: 1, y: 0 },
                             }}>
                                <div className='pt-'>
                                    {/* <img src="check-markx.png" alt="" className='w-[64px]'/> */}
                                </div>
                                <div className='pt-2 flex'>
                                <i class="ri-check-line text-[1.3rem] text-[#1C7FBB]"></i>  
                                <p className='pl-2 text-[black] font-medium text-[1.2rem]'>Radical Gradualist</p>
                                </div>
                                
                            </motion.div>
                            <motion.div className='pl-[5%] pr-2'
                             initial="hidden"
                             whileInView="visible"
                             viewport={{ once: true, amount: 0.5 }}
                             transition={{ delay: 0.5, duration: 0.5 }}              
                             variants={{
                             hidden: { opacity: 0, y: 50 },
                             visible: { opacity: 1, y: 0 },
                             }}>
                                <p className='text-[black]'>Dream big, but have a deliberate plan to get there</p>
                    </motion.div>
                    <motion.div className='flex pl-3 pt-2'
                             initial="hidden"
                             whileInView="visible"
                             viewport={{ once: true, amount: 0.5 }}
                             transition={{ delay: 0.5, duration: 0.5 }}              
                             variants={{
                             hidden: { opacity: 0, y: 50 },
                             visible: { opacity: 1, y: 0 },
                             }}>
                                <div className='pt-'>
                                    {/* <img src="check-markx.png" alt="" className='w-[64px]'/> */}
                                </div>
                                <div className='pt-5 flex'>
                                <i class="ri-check-line text-[1.3rem] text-[#1C7FBB]"></i>  
                                <p className='pl-2 text-[black] font-medium text-[1.2rem]'>Rigor, Not Rigor Mortis</p>
                                </div>
                                
                            </motion.div>
                            <motion.div className='pl-[5%] pr-2'
                             initial="hidden"
                             whileInView="visible"
                             viewport={{ once: true, amount: 0.5 }}
                             transition={{ delay: 0.5, duration: 0.5 }}              
                             variants={{
                             hidden: { opacity: 0, y: 50 },
                             visible: { opacity: 1, y: 0 },
                             }}>
                                <p className='text-[black]'>Be thorough, but balance analysis with action .</p>
                    </motion.div>


                           
                  </div>

                  <div className='bg-[reen] h-fit flex justify-center pl-2 pr-2 pt-5'>
                    <img src="https://media.istockphoto.com/id/1332113666/photo/young-businesswoman-using-digital-tablet.jpg?s=612x612&w=0&k=20&c=N_YLBRcbTGIykJRI7XjXXitH7TUTfr8orLzuV1mxWJc=" alt=""/>
                  </div>
            </div>

           
        </div>
        <div className='bg-[#E6F4FC] p-5' id='Review'>
            <div className='mx-auto overflow-hidden md:max-w-6xl'>
            <Swiper/>   
            </div>
        </div>
        
        <div className=' bg-[ece6e6] h-fit '>
                <div className='mx-auto overflow-hidden md:max-w-6xl'>
                    <div className="pl-5 pb-10">
                        <p className="text-[1.8rem] font-semibold pt-5 text-center xl:text-start lg:text-start">
                            Trusted Companies
                        </p>
                    </div>
                    <TrustSwipe/>
                </div>

               
        </div>

       

       <Footer/>
       </div> )}
       </div>
        
    </>
  )
}

export default About