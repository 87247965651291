

import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick"

const TrustSwipe = () => {

    var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 5,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 2
            }
          },
          {
            breakpoint: 550,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
  return (
    <div className="carousel pb-10">
        {/* <h2>Carusole</h2> */}

    <Slider {...settings}>
        <div className="box overflow-hidden ">
            <div className='flex justify-center pt-[10%]'>
                <img src="imagex1.png" alt="" className='w-[100px]'/>
            </div>
        </div>
        <div className="box">
            <div className='flex justify-center pt-[10%]'>
                <img src="imagex2.webp" alt="" className='w-[100px]'/>
            </div>
        </div>
        <div className="box">
            <div className='flex justify-center pt-[10%]'>
                <img src="googlex.png" alt="" className='w-[100px]'/>
            </div>
        </div>
        <div className="box">
            <div className='flex justify-center pt-[10%]'>
                <img src="imagex3.png" alt="" className='w-[100px]'/>
            </div>
        </div>
        <div className="box">
            <div className='flex justify-center pt-[8%]'>
                <img src="imagex4.webp" alt="" className='w-[100px]'/>
            </div>
        </div>
        <div className="box pb-[15%]">
            <div className='flex justify-center pt-[14%]'>
                <img src="shieldxx.jpg" alt="" className='w-[100px]'/>
            </div>
        </div>
    </Slider>


    
    </div>
  )
}

export default TrustSwipe;