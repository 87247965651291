
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from "./Components/Navbar"
import Home from "./Components/Home"
import Help from './Components/Help';
import Blackmail from './Components/Blackmail';
import CryptoRestore from './Components/CryptoRestore';
import Activation from './Components/Activation';
import RomanceScamRestore from './Components/RomanceScamRestore';
import SocialMedia from './Components/SocialMedia';
import About from './Components/About';
import Contact from './Components/Contact';

function App() {
  return (
    <Routes>
      <Route path="/Navbar" element={<Navbar/>}/>
      <Route path='/' element={<Home/>}/>
      <Route path='/Help' element={<Help/>}/>
      <Route path="/Blackmail"element={<Blackmail/>}/>
      <Route path='/CryptoRestore' element={<CryptoRestore/>}/>
      <Route path='/Activation' element={<Activation/>}/>
      <Route path="/RomanceScamRestore" element={<RomanceScamRestore/>}/>
      <Route path="/SocialMedia" element={<SocialMedia/>}/>
      <Route path='/About' element={<About/>}/>
      <Route path="/Contact" element={<Contact/>}/>
    </Routes>
  );
}

export default App;
