import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import AimatedText from './AnimatedText'
import { Link } from 'react-router-dom'
import Footer from './Footer'
import { SyncLoader } from 'react-spinners'
// import CryptoSwipe from './CryptoSwipe'
import { motion } from 'framer-motion'
import Swiper from "./Swiper";

const SocialMedia = () => {
    const [isLoading, setIsLoading] = useState(true)


    useEffect(()=> {
        setIsLoading(true)
        setTimeout(()=> {
           setIsLoading(false)

        },5000)
   }, [])
  return (
    <>
    <div>
    {isLoading ? (
        
        
        <div className='flex justify-center mx-auto  bg__Load bg-black/10 backdrop-blur-lg h-[100vh] pt-[75%] xl:pt-[25%] 2xl:pt-[25%] lg:pt-[25%] sm:pt-[50%] md:pt-[50%]'>
        {/* <ScaleLoader color={"#030d26"} loading={isLoading}  size={100} className='  '/> */}
        <SyncLoader color={"#1C7FBB"}  loading={isLoading} size={20} className=""/>
        </div>
        
    
  ) : ( <div className=''>
        <Navbar/>
        <div className='mx-auto  overflow-hidden md:max-w-7xl'>
            <div className=" pt-[20%] xl:pt-[10%] lg:pt-[10%]">
                <AimatedText text="Social Media Hack/Recovery" className='text-[2rem] xl:text-[3rem] text-[#1C7FBB] text-center'/>
            </div>
            <div className="flex justify-center pt-4">
              <Link to="/"><p className='font-semibold'>Home <span className="text-[#0d0d35]">/ Service</span></p></Link>
            </div>
        </div>

        <div className='mx-auto  overflow-hidden md:max-w-7xl'>
            <div className='grid ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-10'>
                <div className='bg-[reen] h-fit overflow-hidden'>
                    <motion.p className='pt-3 pl-2 pr-1' 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.2, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                    Within the vast tapestry of the digital realm, where social media platforms hold sway as conduits of connection and 
                    elf-expression, we stand resolute in our ability to restore and reclaim what has been lost. Across the expansive 
                    landscape of cyberspace, encompassing platforms such as Instagram, Facebook, TikTok, Twitter, and beyond, we possess 
                    an unrivaled expertise in the art of account restoration.
                    No matter the intricacies of the social media platform or the complexity of the situation at hand, we have honed our 
                    skills to navigate the labyrinthine corridors of account retrieval. Our adept team of specialists possesses an intimate 
                    understanding of the inner workings of these digital realms, equipped with advanced techniques and strategies to swiftly 
                    restore your access and reestablish your virtual presence.</motion.p> <br /> <br />

                    <motion.p 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.3, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>At our service, we are committed to providing our clients with the necessary tools to uncover the truth and gain closure. 
                     This includes the use of advanced social media skills and legal methods to monitor suspected cheating partners or scammers. 
                     While the idea of “hacking” into someone’s account may seem unethical, we believe that in certain cases, it may be necessary 
                     to obtain the evidence needed to support our clients’ claims. However, we only engage in this practice when it is lawful and 
                     with appropriate authorization. Our ultimate goal is to provide our clients with the peace of mind they deserve by delivering 
                     concrete evidence in a professional and ethical manner.
                    </motion.p>

                    <div className='flex justify-center mb-[10%] pl-5 pt-10'>
                      <Link to="/Contact"><button className='mt-5 Con__btn'>Contact Us</button></Link>
                    </div>
                </div>

                <div className='bg-[reen] h-fit flex justify-center pt-10'>
                        <div >
                            <img src="https://cdn.pixabay.com/photo/2021/07/09/22/17/social-media-6400130_1280.png" alt="" />
                        </div>
                </div>
            </div>

            
        </div>
        <div className='bg-[#E6F4FC] pb-10 pt-5 pl-3 pr-3' id='Review'>
        <div className='mx-auto overflow-hidden md:max-w-6xl'>
            <Swiper/>   
        </div>
        </div>

        <Footer/>
        </div> )}
    </div>
    
    </>
  )
}

export default SocialMedia;