import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import AimatedText from './AnimatedText'
import { Link } from 'react-router-dom'
import Footer from './Footer'
import { SyncLoader } from 'react-spinners'
import CryptoSwipe from './CryptoSwipe'
import { motion } from 'framer-motion'
import Swiper from "./Swiper"

const CryptoRestore = () => {
    const [isLoading, setIsLoading] = useState(true)


    useEffect(()=> {
        setIsLoading(true)
        setTimeout(()=> {
           setIsLoading(false)

        },5000)
   }, [])
  return (
    <>
    <div>
    {isLoading ? (
        
        
        <div className='flex justify-center mx-auto  bg__Load bg-black/10 backdrop-blur-lg h-[100vh] pt-[75%] xl:pt-[25%] 2xl:pt-[25%] lg:pt-[25%] sm:pt-[50%] md:pt-[50%]'>
        {/* <ScaleLoader color={"#030d26"} loading={isLoading}  size={100} className='  '/> */}
        <SyncLoader color={"#1C7FBB"}  loading={isLoading} size={20} className=""/>
        </div>
        
    
  ) : ( <div className=''>
        <Navbar/>
        <div className='mx-auto  overflow-hidden md:max-w-7xl'>
            <div className=" pt-[20%] xl:pt-[10%] lg:pt-[10%]">
                <AimatedText text="Crypto Tracing/Restore" className='text-[2rem] xl:text-[3rem] text-[#1C7FBB] text-center'/>
            </div>
            <div className="flex justify-center pt-4">
              <Link to="/"><p className='font-semibold'>Home <span className="text-[#0d0d35]">/ Service</span></p></Link>
            </div>
        </div>

        <div className='mx-auto  overflow-hidden md:max-w-7xl'>
            <div className='grid ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-10'>
                <div className='bg-[reen] h-fit overflow-hidden'>
                    <motion.p className='pt-3 pl-2 pr-1' 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.2, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                    The Cryptocurrency Recovery Project represents a paradigm-shifting endeavor, embodying a fervent commitment to aiding both 
                    individuals victimized by cybercrimes and enterprises in their pursuit of reacquiring lost or pilfered assets. Our cadre of 
                    consummate professionals stands fortified with state-of-the-art asset tracing and recovery technology, fostering 
                    connections with victims of cybercrimes, empathetically comprehending their distinctive circumstances, and offering 
                    unwavering support in their quest to reclaim what is rightfully theirs. With an unwavering dedication to excellence, we 
                    forge a path towards asset recovery, propelling the resilience of our clients amidst the complex realm of digital 
                    transgressions.</motion.p> <br /> <br />

                    <motion.p 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.3, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>Also the Project empathizes with the distressing impact of cybercrimes on individuals and companies. Our 
                    dedicated team of experts tirelessly assists victims in recovering their lost assets. With cutting-edge technology, we trace 
                    and track stolen or lost assets, providing unparalleled asset recovery services. Trust us to alleviate your burdens and deliver 
                    exceptional results.
                    </motion.p>

                    <div className='flex justify-center mb-[10%] pl-5 pt-10'>
                        <button className='mt-5 Con__btn'>Contact Us</button>
                    </div>
                </div>

                <div className='bg-[reen] h-fit flex justify-center'>
                        <div >
                            <img src="https://hcr2018.s3.eu-west-2.amazonaws.com/wp-content/uploads/2022/03/16154632/Blog-New-ground-broken-in-judgment-on-cryptofraud.jpg" alt="" />
                        </div>
                </div>
               
            </div>
            
            <CryptoSwipe/>
        </div>

        <div className='bg-[#E6F4FC] pb-10 pt-5 pl-3 pr-3' id='Review'>
                    <div className='mx-auto overflow-hidden md:max-w-6xl'>
                        <Swiper/>   
                    </div>
             </div>


        <Footer/>
        </div> )}
    </div>
    
    </>
  )
}

export default CryptoRestore;