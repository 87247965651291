import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
// import TransitionEffect from './TransitionEffect';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleServices = () => {
    setIsServicesOpen(!isServicesOpen);
  }

  return (
    <nav className="bg-[white] z-40 fixed text-white shadow-lg w-full ">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center overflow-hidden">
          
             <img src="classix.png" alt="" className='w-[25%] xl:w-[20%] flex md:hidden xl:flex lg:flex'/>
            
          </div>
          <div className="flex items-center">
            <div className="hidden md:block">
            <Link to="/" className="ml-4 px-3 py-2 rounded-md text-base font-medium text-[#33425c] hover:text-[#1c7fbb]">
                Home
              </Link>
              {/* <Link to="/faq" className="ml-4 px-3 py-2 rounded-md text-base font-medium text-[#2f2f2f] hover:text-[#ff4650]">
                FAQ
              </Link> */}
              <div className="relative inline-block text-left">
                <div className="flex">
                  <button
                    onClick={toggleServices}
                    className="text-[#2f2f2f] hover:text-[#1c7fbb]  py-2 pl-4  rounded-md text-sm font-medium"
                  >
                    Services
                  </button> <i class="ri-arrow-down-s-line text-[1.2rem]  pt-[5.5px] text-[#1c7fbb] cursor-pointer" onClick={toggleServices}></i>
                  </div>
                  {isServicesOpen && (
                    <div className="absolute z-10  mt-2 bg-white border border-gray-200 rounded-md shadow-lg w-[20rem]">
                      <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu ">
                        <Link to="/Blackmail" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                        Blackmail/Extortion
                        </Link>
                        <Link to="/CryptoRestore" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                        Crypto Recovery
                        </Link>
                        <Link to="/Activation" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                          Account Activation
                        </Link>
                        <Link to="/RomanceScamRestore"  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                        Romance Scam Recovery
                        </Link>
                        <Link to="/SocialMedia" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                        Social Media Hack/Recovery
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              
              <a href="#Review" className="ml-4 px-3 py-2 rounded-md text-base font-medium text-[#2f2f2f] hover:text-[#1c7fbb]">
                Reviews
              </a>
              <Link to="/Contact" className="ml-4 px-3 py-2 rounded-md text-base font-medium text-[#2f2f2f] hover:text-[#1c7fbb]">
                Contact
              </Link>
              <Link to="/about" className="ml-4 px-3 py-2 rounded-md text-base font-medium text-[#2f2f2f] hover:text-[#1c7fbb]">
                About
              </Link>
              <button className='bg-[#1c7fbb] p-3 rounded-md'>
                    <a href="https://classicretrieval.com/signup/" target='_blank' rel="noreferrer" >
                      <p className=' text-white'> Signup</p>
                    </a>
              </button>

              
            </div>
            <div className="md:hidden">
              <button
                onClick={toggleSidebar}
                className="p-2  bg-[#1c7fbb] focus:outline-none rounded-full"
              >
                {isOpen ? (
                  <div className="text-gray-900 hidden"></div>
                ) : (
                  <FaBars className="text-[white] text-2xl" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
         <div>
          {/* <TransitionEffect/> */}
         {isOpen && <div className="fixed inset-0 bg-black opacity-70 z-40"></div>}
         <nav className={`fixed z-40 inset-y-0 left-0 bg-[black] text-white w-64 px-4 py-6 transform-gpu transition-transform duration-300 ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
           <div className="flex items-center justify-between mb-6">
             {/* <h1 className="text-xl font-semibold"></h1> */}
             <button onClick={toggleSidebar} className="text-white focus:outline-none">
               {isOpen ? (
                 <FaTimes className="text-2xl" />
               ) : (
                 <FaBars className="text-2xl" />
               )}
             </button>
           </div>
           <div className="space-y-4">
             <Link to="/" onClick={toggleSidebar} className="block text-gray-300 hover:text-white transition-colors duration-200">Home</Link>
             <div className="relative inline-block text-left">
                <div className='flex'>
                  <button
                    onClick={toggleServices}
                    className="  block text-gray-300 hover:text-white transition-colors duration-200"
                  >
                    Services
                  </button><i class="ri-arrow-down-s-line text-[1.2rem] pl-3 pt-2 text-[#1c7fbb] cursor-pointer" onClick={toggleServices}></i>
                  </div>
                  {isServicesOpen && (
                    <div className="absolute z-10 mt-2 bg-white border border-gray-200 rounded-md shadow-lg w-[20rem]">
                      <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu ">
                        <Link to="/Blackmail" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                        Blackmail/Extortion
                        </Link>
                        <Link to="/CryptoRestore" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                        Crypto Recovery
                        </Link>
                        <Link to="/Activation" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                          Account Activation
                        </Link>
                        <Link to="/RomanceScamRestore"  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                        Romance Scam Recovery
                        </Link>
                        <Link to="/SocialMedia" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                        Social Media Hack/Recovery
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
           
             <a href="#Review" onClick={toggleSidebar} className="block text-gray-300 hover:text-white transition-colors duration-200">Reviews</a>
             <Link to="/Contact" onClick={toggleSidebar} className="block text-gray-300 hover:text-white transition-colors duration-200">Contact</Link>
             <Link to="/about" onClick={toggleSidebar} className="block text-gray-300 hover:text-white transition-colors duration-200">About</Link>
             <a href="https://classicretrieval.com/signup/" target='_blank' rel="noreferrer" onClick={toggleSidebar} className="block text-gray-300 hover:text-white transition-colors duration-200">Sign-up</a>
             <a href="https://classicretrieval.com/signup/login" target='_blank' rel="noreferrer" onClick={toggleSidebar} className="block text-gray-300 hover:text-white transition-colors duration-200">Login</a>
           </div>
         </nav>
       </div>
      )}
    </nav>
  );
}

export default Navbar;
