import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import AimatedText from './AnimatedText'
import { Link } from 'react-router-dom'
import Footer from './Footer'
import { SyncLoader } from 'react-spinners'
import { motion } from 'framer-motion'
import ActivationSlick from './ActivationSlick'
import Swiper from "./Swiper"

const Activation = () => {
    const [isLoading, setIsLoading] = useState(true)


    useEffect(()=> {
        setIsLoading(true)
        setTimeout(()=> {
           setIsLoading(false)

        },5000)
   }, [])
  return (
    <>
    <div>
    {isLoading ? (
        
        
        <div className='flex justify-center mx-auto  bg__Load bg-black/10 backdrop-blur-lg h-[100vh] pt-[75%] xl:pt-[25%] 2xl:pt-[25%] lg:pt-[25%] sm:pt-[50%] md:pt-[50%]'>
        {/* <ScaleLoader color={"#030d26"} loading={isLoading}  size={100} className='  '/> */}
        <SyncLoader color={"#1C7FBB"}  loading={isLoading} size={20} className=""/>
        </div>
        
    
  ) : ( <div className=''>
        <Navbar/>
        <div className='mx-auto  overflow-hidden md:max-w-7xl'>
            <div className=" pt-[20%] xl:pt-[10%] lg:pt-[10%]">
                <AimatedText text="Account Activation" className='text-[2rem] xl:text-[3rem] text-[#1C7FBB] text-center'/>
            </div>
            <div className="flex justify-center pt-4">
              <Link to="/"><p className='font-semibold'>Home <span className="text-[#0d0d35]">/ Service</span></p></Link>
            </div>
        </div>

        <div className='mx-auto  overflow-hidden md:max-w-7xl'>
            <div className='grid ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-10'>
                <div className='bg-[reen] h-fit overflow-hidden'>
                    <motion.p className='pt-3 pl-2 pr-1' 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.2, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                    In a world increasingly intertwined with social media and the digital landscape, encountering challenges such as account 
                    deactivation or social media issues can be disconcerting. We recognize the perturbation that arises when access to these 
                    platforms is disrupted, and we extend our hand as a reliable resource for resolution.
                    At our platform, we specialize in assisting individuals facing account access hurdles, including social media and 
                    deactivation concerns. Whether it's the need to regain access to a deactivated account or resolve any social media-related 
                    issues, our team of experts is well-versed in the intricacies of various platforms. Count on us to provide efficient and 
                    effective solutions tailored to your unique circumstances.</motion.p> <br /> <br />

                    <motion.p 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.3, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>If you find yourself grappling with social media or account deactivation predicaments, we urge you to reach out to us. 
                     Our dedicated team stands ready to assist, alleviating your worries and guiding you towards a swift resolution.
                    </motion.p>

                    <div className='flex justify-center mb-[10%] pl-5 pt-10'>
                       <Link to="/Contact"><button className='mt-5 Con__btn'>Contact Us</button></Link>
                    </div>
                </div>

                <div className='bg-[reen] h-fit flex justify-center'>
                        <div >
                            <img src="https://media.istockphoto.com/id/1154231467/photo/gui-concept.jpg?s=170667a&w=0&k=20&c=DIsz25ZisfXcOgXq9bLh4-OmxoTShEoMSHfRyTcWRcI=" alt="" />
                        </div>
                </div>

                

        
        </div>
        
            <ActivationSlick/>
        </div>
        <div className='bg-[#E6F4FC] pb-10 pt-5' id='Review'>
            <div className='mx-auto overflow-hidden md:max-w-6xl'>
                <Swiper/>   
            </div>
        </div>



        <Footer/>
        </div> )}
    </div>
    
    </>
  )
}

export default Activation;