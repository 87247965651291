
import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick"
 import { Avatar, Card } from '@mui/material';


const ReviewSlick = () => {

    var settings = {
        dots: false,
        infinite: true,
          autoplay: true,
        speed: 700,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 5,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2
            }
          },
          {
            breakpoint: 550,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
  return (
    
    <div className="carouselx h-fit">
       <div className='flex justify-center'>
        <p className='text-black text-[1.7rem] font-semibold'>Reviews</p>
       </div>

    <Slider {...settings}>
        
        <Card className="box1 overflow-hidden pl-3 pr-2 " 
       >
            <div className='  bg-[e22ee2ee]  h-fit Rev__Border'>
                <div className='pl-5 flex pt-4 '>
                    <Avatar alt="Remy Sharp" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKct0JFTKdVV5NNmK88MmnRix7yUqq688ttMdqG2X1eh_M3bw1rrhz5wTHQEHycY_82S0&usqp=CAU" className=''/>
                    <div className='pl-2 pt-2'>
                        <p>lyala wills</p>
                    </div>
                </div>

               <div className=''>
                    <p className='pl-5 pt-4 pr-1 '>
                    I fell victim to a romance scam, losing a significant sum of $500,000. However, thanks to the swift and efficient 
                    assistance of ClassicRetrieval  I was able to reclaim my money without wasting any time. Their dedicated team 
                    of experts worked tirelessly to ensure a successful recovery, providing me with invaluable support throughout the 
                    process. If you find yourself in a similar situation, trust ClassicRetrieval to help you regain what's
                     rightfully yours.
                    </p>

                    <div className='flex pl-5 pt-4'>
                        <p><i class="ri-map-pin-2-line text-[1.4rem] text-[#1C7FBB]]"></i></p>
                        <p className="pl-2 pt-1 text-[#1C7FBB] pb-6">Savannah, Georgia</p>
                    </div>
               </div>
            </div>
        </Card>

        <Card className="box1 overflow-hidden pl-3 pr-2 shadow-md ring-2 ring-gray-200">
            <div className='  bg-[e22ee2ee]  h-fit Rev__Border'>
                <div className='pl-5 flex pt-4'>
                    <Avatar alt="Remy Sharp" src="https://qph.cf2.quoracdn.net/main-qimg-6e289e207ce33cff711de7ee91e6b1e2-pjlq" className=''/>
                    <div className='pl-2 pt-2'>
                        <p>Gilbert Phil</p>
                    </div>
                </div>

               <div className=''>
                    <p className='pl-5 pt-4 pr-1 '>
                    I found myself a victim of a sophisticated scam, losing a substantial portion of my hard-earned life savings through an 
                    elaborate fake broker investment site. The ordeal left me devastated and uncertain about my financial future. However, 
                    my fortunes took a remarkable turn when I discovered this exceptional tech company that specializes in restitution and 
                    recovery. With their unmatched expertise and seamless process, they swiftly restored my funds, providing me with a 
                    renewed sense of hope and financial security. Their professionalism and dedication are truly unparalleled, making 
                    them the unequivocal leaders in their field. If you've fallen victim to a similar scam, trust this extraordinary 
                    tech company to deliver the justice and restitution you deserve.
                    </p>

                    <div className='flex pl-5 pt-4'>
                        <p><i class="ri-map-pin-2-line text-[1.4rem] text-[#1C7FBB]"></i></p>
                        <p className="pl-2 pt-1 text-[#1C7FBB] pb-6">Silver City, New Mexico</p>
                    </div>
               </div>
            </div>
        </Card>

        <Card className="box1 overflow-hidden pl-3 pr-2">
            <div className='  bg-[e22ee2ee]  h-fit Rev__Border'>
                <div className='pl-5 flex pt-4'>
                    <Avatar alt="Remy Sharp" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmTMfec3AoKUnqm5Wo20vd1KLHxzh91Y8LMAnQ75s-7kgXhJIuaaLW4EFpcSqVhyX6T6A&usqp=CAU" className=''/>
                    <div className='pl-2 pt-2'>
                        <p>Touleen Yekta</p>
                    </div>
                </div>

               <div className=' '>
                    <p className='pl-5 pt-4 pr-1'>
                    For an agonizing stretch of time, I found myself ensnared in the clutches of a relentless blackmailer, succumbing to 
                    their demands and draining my financial resources. Desperate, I even resorted to taking out a loan. However, a glimmer 
                    of hope emerged when I discovered the exceptional services of ClassicRetrieval. Through their tireless efforts, they 
                    unveiled the identity of the tormentor, resulting in their apprehension and the recovery of every penny they had 
                    stolen from me. Gratitude fills my heart as I reflect upon ClassicRetrieval's unwavering commitment to justice, 
                    liberating me from the clutches of my malevolent captor while restoring both my financial stability and peace of mind. 
                    </p>

                    <div className='flex pl-5 pt-4'>
                        <p><i class="ri-map-pin-2-line text-[1.4rem] text-[#1C7FBB]"></i></p>
                        <p className="pl-2 pt-1 text-[#1C7FBB] pb-6">Wawa, Ontario.</p>
                    </div>
               </div>
            </div>
        </Card>

        <Card className="box1 overflow-hidden pl-3 pr-2">
            <div className='  bg-[e22ee2ee]  h-fit Rev__Border'>
                <div className='pl-5 flex pt-4'>
                    <Avatar alt="Remy Sharp" src="https://photos.spareroom.com/images/flatshare/listings/large/20/20/202050274.jpg" className=''/>
                    <div className='pl-2 pt-2'>
                        <p>Allegra Casimir</p>
                    </div>
                </div>

               <div className=''>
                    <p className='pl-5 pt-4 pr-1'>
                    Upon discovering my cheating boyfriend with the help of ClassicRetrieval, I realized my phone had been tampered with, 
                    rendering me unable to access my social media accounts. Swiftly responding to my plight,ClassicRetrieval efficiently 
                    restored my socials, ensuring no time was wasted. Their expertise and prompt action salvaged my digital presence, 
                    providing much-needed relief from the intrusion. I am immensely grateful for their professional assistance in 
                    safeguarding my online realm.
                    </p>

                    <div className='flex pl-5 pt-4'>
                        <p><i class="ri-map-pin-2-line text-[1.4rem] text-[#1C7FBB]"></i></p>
                        <p className="pl-2 pt-1 text-[#1C7FBB] pb-6">Hunstanton, Norfolk.</p>
                    </div>
               </div>
            </div>

            
        </Card>

        <Card className="box1 overflow-hidden">
            <div className='  bg-[e22ee2ee]  h-fit Rev__Border'>
                <div className='pl-5 flex pt-4'>
                    <Avatar alt="Remy Sharp" src="https://e00-marca.uecdn.es/assets/multimedia/imagenes/2022/11/09/16679888455169.jpg" className=''/>
                    <div className='pl-2 pt-2'>
                        <p>Rose Mayham</p>
                    </div>
                </div>

               <div className=''>
                    <p className='pl-5 pt-4 pr-1'>
                    I found myself entangled in a web of deception spun by a fraudulent recovery and espionage firm, resulting in substantial 
                    financial losses and unauthorized access to my bank accounts. However, my fortunes changed when ClassicRetrieval intervened, 
                    promptly restoring the funds that had been unjustly taken from me. Not stopping there, they diligently pursued legal 
                    action, leading to the incarceration of the scammers responsible for my plight. Their expertise not only provided me with 
                    financial relief but also ensured that these criminals faced the consequences of their actions. I am deeply thankful for 
                    ClassicRetrieval's invaluable assistance in rectifying the situation and delivering justice. 
                    </p>

                    <div className='flex pl-5 pt-4'>
                        <p><i class="ri-map-pin-2-line text-[1.4rem] text-[#1C7FBB]"></i></p>
                        <p className="pl-2 pt-1 text-[#1C7FBB] pb-6">San Diego, California</p>
                    </div>
               </div>
            </div>

            
        </Card>
        
    </Slider>


    
    </div>
  )
}

export default ReviewSlick;