import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import AimatedText from './AnimatedText'
import { Link } from 'react-router-dom'
import Footer from './Footer'
import { SyncLoader } from 'react-spinners'
import Swiper from "./Swiper"

const Blackmail = () => {
    const [isLoading, setIsLoading] = useState(true)


    useEffect(()=> {
        setIsLoading(true)
        setTimeout(()=> {
           setIsLoading(false)

        },5000)
   }, [])
  return (
    <>
    <div>
    {isLoading ? (
        
        
        <div className='flex justify-center mx-auto  bg__Load bg-black/10 backdrop-blur-lg h-[100vh] pt-[75%] xl:pt-[25%] 2xl:pt-[25%] lg:pt-[25%] sm:pt-[50%] md:pt-[50%]'>
        {/* <ScaleLoader color={"#030d26"} loading={isLoading}  size={100} className='  '/> */}
        <SyncLoader color={"#1C7FBB"}  loading={isLoading} size={20} className=""/>
        </div>
        
    
  ) : ( <div className=''>
        <Navbar/>
        <div className='mx-auto  overflow-hidden md:max-w-7xl'>
            <div className=" pt-[20%] xl:pt-[10%] lg:pt-[10%]">
                <AimatedText text="Blackmail or Extortion" className='text-[2rem] xl:text-[3rem] text-[#1C7FBB] text-center'/>
            </div>
            <div className="flex justify-center pt-4">
              <Link to="/"><p className='font-semibold'>Home <span className="text-[#0d0d35]">/ Service</span></p></Link>
            </div>
        </div>

        <div className='mx-auto  overflow-hidden md:max-w-7xl'>
            <div className='grid ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-10'>
                <div className='bg-[reen] h-fit overflow-hidden'>
                    <p className='pt-3 pl-2 pr-1'>
                    Blackmail, a grave offense, entails the illicit practice of coercing monetary compensation or other advantageous 
                    concessions by withholding potentially damaging information concerning an individual. For victims ensnared within 
                    its sinister grasp, the experience can be deeply distressing, rendering them defenseless and exposed to the whims 
                    of their blackmailers. While the occurrences of blackmail may manifest within a multitude of contexts, they are 
                    generally classified into three fundamental categories: extortion, coercion, and commercial pressure. Each type 
                    represents a distinct manifestation of this nefarious act, further illuminating the intricate nature of this 
                    reprehensible crime. <br /> <br />

                    At  ClassicRetrieval, we comprehend the devastating consequences of blackmail on individuals and their loved ones. Our comprehensive 
                    solutions cater to victims of internet blackmail, sextortion, online threats, extortion, coercion, and commercial pressure. 
                    Contact us if you find yourself in this distressing situation, and let us help you regain control and seek justice. Together, 
                    we can navigate the challenges and safeguard your well-being.
                    </p>

                    <div className='flex justify-center mb-[10%] pl-5 pt-10'>
                    <Link  to="/Contact"><button className='mt-5 Con__btn'>Contact Us</button></Link> 
                    </div>
                </div>

                <div className='bg-[reen] h-fit flex justify-center'>
                        <div>
                            <img src="https://media.istockphoto.com/id/1329528786/photo/the-concept-of-extortion-blackmail.jpg?s=612x612&w=0&k=20&c=JcqqzHQpQD8kUOGjJpDbJLJuoONPfAjimz7pVhj2rx4=" alt="" />
                        </div>
                </div>
            </div>

            
        </div>

        <div className='bg-[#E6F4FC] pb-10 pt-5 pl-3 pr-3' id='Review'>
                <div className='mx-auto overflow-hidden md:max-w-6xl'>
                    <Swiper/>   
                 </div>
            </div>
        <div className='mt-'>
        <Footer/>
        </div>
        </div> )}
    </div>
    
    </>
  )
}

export default Blackmail