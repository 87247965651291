import React, { useState } from 'react';
import { FaTelegram,  FaInstagram, FaEnvelope } from 'react-icons/fa';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Send email or perform any necessary action with the email value
    setSubscribed(true);
    setEmail('');
  };

  return (
    <footer className=" bg-[#0e1c1f] text-white py-8 pl-2 pr-2">
      <div className="container mx-auto flex flex-col items-center justify-center">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-8">
          <div className="mb-4 md:mb-0">
            <h2 className="text-lg font-bold mb-4">Contact</h2>
            <ul className="space-y-2">
              <li>
                <a href="mailto:support@classicretrieval.com">support@classicretrieval.com</a>
              </li>
              <li>
                {/* <a href="/">Site Map</a> */}
              </li>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/">Services</a>
              </li>
              <li>
                <a href="/About">About</a>
              </li>

              <li>
                <a href="/Contact">Email Us</a>
              </li>
            </ul>
          </div>
          <div className="mb-4 md:mb-0">
            <h2 className="text-lg font-bold mb-4">Follow Us</h2>
            <ul className="flex gap-3">
              <li>
                <a href="https://t.me/classicretrieval" target='_blank' rel="noreferrer">
                  <FaTelegram className="text-white text-xl hover:text-gray-500" />
                </a>
              </li>
              <li>
                {/* <a href="#">
                  <FaWhatsapp className="text-white text-xl hover:text-gray-500" />
                </a> */}
              </li>
              <li>
                <a href="https://www.instagram.com/classicretrieval?igsh=OGQ5ZDc2ODk2ZA==" target='_blank' rel="noreferrer">
                  <FaInstagram className="text-white text-xl hover:text-gray-500" />
                </a>
              </li>
              <li>
                <a href="mailto:support@classicretrieval.com" target='_blank' rel="noreferrer">
                  <FaEnvelope className="text-white text-xl hover:text-gray-500" />
                </a>
              </li>
            </ul>
          </div>
          <div className="mb-4 md:mb-0">
            <h2 className="text-lg font-bold mb-4">Subscribe</h2>
            {subscribed ? (
              <p className="text-[green]">Thank you for subscribing!</p>
            ) : (
              <form onSubmit={handleSubmit}>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleInputChange}
                  className="px-4 py-2 w-full rounded-md focus:outline-none focus:ring-2 text-black focus:ring-[#f04021]"
                  required
                />
                <button
                  type="submit"
                  className="mt-2 px-4 py-2 bg-[#1C7FBB] text-white rounded-md hover:bg- focus:outline-none focus:bg-[#f04021]"
                >
                  Subscribe
                </button>
              </form>
            )}
          </div>
          <div className="mb-4 md:mb-0">
            {/* <h2 className="text-lg font-bold mb-4"></h2> */}
          <img src="classix.png" alt="" className='w-[33%]'/>
          </div>
        </div>
        
        <p className="text-sm mt-4">
         2012 ClassicRetrieval. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
