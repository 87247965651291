import React from 'react'
import Navbar from './Navbar'
import AimatedText from './AnimatedText'
import { motion } from "framer-motion"
import Footer from './Footer'
import { Link } from "react-router-dom"
import {useState, useEffect} from "react"
import { SyncLoader } from 'react-spinners'
import Swiper from "./Swiper"

const Help = () => {
    const [isLoading, setIsLoading] = useState(true)


    useEffect(()=> {
        setIsLoading(true)
        setTimeout(()=> {
           setIsLoading(false)

        },5000)
   }, [])

  return (
   
    
    <>
    <div>
    {isLoading ? (
        
        
        <div className='flex justify-center mx-auto  bg__Load bg-black/10 backdrop-blur-lg h-[100vh] pt-[75%] xl:pt-[25%] 2xl:pt-[25%] lg:pt-[25%] sm:pt-[50%] md:pt-[50%]'>
        {/* <ScaleLoader color={"#030d26"} loading={isLoading}  size={100} className='  '/> */}
        <SyncLoader color={"#1C7FBB"}  loading={isLoading} size={20} className=""/>
        </div>
        
    
  ) : ( <div className=''>
        <Navbar/>
        <div className='bg-[#E6F4FC] h-fit pt-[20%] xl:pt-[10%] lg:pt-[10%]'>
                <div className='mx-auto  overflow-hidden md:max-w-6xl'>
                    <div>
                        <AimatedText text="How we help" className='text-[2rem] xl:text-[3rem] text-[#1C7FBB] text-center'/>
                    </div>

                    <motion.div 
                    >
                        <p className='text-center pt-4 pl-2 pr-1'>
                        With our unwavering commitment to justice, we specialize in providing flexible levels of forensic cryptocurrency 
                        investigations and litigation services, specifically designed to restore funds from malicious scammers. Leveraging 
                        our extensive expertise and advanced techniques, we diligently pursue every lead, leaving no stone unturned in the 
                        quest to retrieve your hard-earned money. Our dedicated team of professionals is equipped with the knowledge and tools 
                        to trace complex financial transactions, uncover hidden assets, and build a strong case against the fraudsters. Count 
                        on us to navigate the intricate world of cryptocurrencies, ensuring that justice is served and your funds are restored.
                        </p>
                    </motion.div>
                </div>

            <div className='pt-5 mx-auto  overflow-hidden md:max-w-7xl'>
                <div className='grid ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 mt-[5%]'>
                        <div className='bg-[ink] h-fit overflow-hidden '>
                                <div className='pt-8'>
                                    <img src="https://www.pwc.co.za/en/assets/images/Heroes/hero-deals-and-analytics.jpg" alt="" />
                                </div>
                        </div>
                        <div className='bg-[ink] h-fit'>
                                

                            <div className='pl-5'>
                                <p className='text-[1.3rem] font-semibold pt-5 text-[#1C7FBB]'>Forensic services</p>
                            </div>

                            <motion.div 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.7 }}
                            transition={{ delay: 0.3, duration: 0.7 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                <p className='pl-3 pr-2 pt-2'>
                                Harnessing the collective power of our expansive global network, ClassicRetrieval boasts a formidable team of over 3000 seasoned professionals. 
                                These individuals possess a diverse range of expertise, including accounting, investigation, intelligence gathering, technology proficiency, 
                                ethical penetration, and profound industry acumen. Operating in unison with unwavering dedication, our professionals deploy consistent 
                                global methodologies, meticulously crafted to mitigate reputational risk and commercial losses. Moreover, our comprehensive approach aims 
                                to optimize the value derived from pre-existing contracts, ensuring maximum returns for our esteemed clientele. With a commitment to 
                                excellence and an unwavering focus on results, ClassicRetrieval stands as a trusted partner in navigating complex challenges, securing 
                                favorable outcomes, and driving sustainable success. <br /> <br />
                                Fraud, financial tampering, computer crime, employee misconduct, and other wrongdoing necessitate tracing digital trails to uncover the 
                                truth for corporations, law firms, and government agencies.
                                </p>
                            </motion.div>
                        </div>
                </div>
            </div>

            
        </div>

        <div className='pt-5 mx-auto  overflow-hidden md:max-w-7xl'>
                <div className='grid ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 mt-[5%]'>
                        <div className='bg-[ink] h-fit overflow-hidden '>
                                

                                <div className='pl-5'>
                                <p className='text-[1.3rem] font-semibold pt-5 text-[#1C7FBB]'>Our unique support</p>
                            </div>

                            <div>
                                

                            <div className='bg-[ndigo] h-fit pb-[10%]'>
                            <motion.p className='pl-2 pt-5'
                             initial="hidden"
                             whileInView="visible"
                             viewport={{ once: true, amount: 0.7 }}
                             transition={{ delay: 0.4, duration: 0.7 }}              
                             variants={{
                             hidden: { opacity: 0, y: 50 },
                             visible: { opacity: 1, y: 0 },
                             }}>
                            Leveraging our firm's extensive expertise and cutting-edge technology, we guarantee prompt and precise submissions to antitrust or 
                                other regulatory bodies, covering every facet of an inquiry. By collaborating with us, you gain a multitude of advantages, including: 
                            </motion.p>

                            <motion.div className='pt-5 flex' 
                             initial="hidden"
                             whileInView="visible"
                             viewport={{ once: true, amount: 0.7 }}
                             transition={{ delay: 0.3, duration: 0.7 }}              
                             variants={{
                             hidden: { opacity: 0, y: 50 },
                             visible: { opacity: 1, y: 0 },
                             }}>
                                <img src="check-mark.png" alt="" className='w-[65px]'/>
                                <p className='pt-5 font-semibold'>End-to-end services.</p>
                                
                            </motion.div>
                            <motion.div 
                             initial="hidden"
                             whileInView="visible"
                             viewport={{ once: true, amount: 0.7 }}
                             transition={{ delay: 0.4, duration: 0.7 }}              
                             variants={{
                             hidden: { opacity: 0, y: 50 },
                             visible: { opacity: 1, y: 0 },
                             }}>
                                <p className='pr-2 pl-2'>Starting with initial meetings with legal teams to determine the scope and format of the project, our team then develops 
                                    and executes review workflows within required deadlines. Also, we perform complex data collection, processing and 
                                    hosting and multiparty production throughout the life of the project. We are also well versed in the latest data 
                                    requirements from specific regulatory agencies, including deduplication methodology, predictive coding, advanced 
                                    analytics and keyword generation.</p>
                            </motion.div>
                            <motion.div className='pt-5 flex' 
                             initial="hidden"
                             whileInView="visible"
                             viewport={{ once: true, amount: 0.7 }}
                             transition={{ delay: 0.5, duration: 0.7 }}              
                             variants={{
                             hidden: { opacity: 0, y: 50 },
                             visible: { opacity: 1, y: 0 },
                             }}>
                                <img src="check-mark.png" alt="" className='w-[65px]'/>
                                <p className='pt-5 font-semibold'>Innovative use of software and solutions.</p>
                                
                            </motion.div>
                                <motion.div 
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.7 }}
                                transition={{ delay: 0.6, duration: 0.7 }}              
                                variants={{
                                hidden: { opacity: 0, y: 50 },
                                visible: { opacity: 1, y: 0 },
                                }}>
                                    <p>Whatever your preferred e-discovery platform, we have the technical expertise and capability to leverage it to provide a flexible 
                                        and cost-effective solution for your Second Request or other requests. Our team is experienced with a broad range of legal review 
                                        software and can use your platform’s data analytics and predictive coding functionality to quickly find relevant documents. If 
                                        appropriate, we can also utilize our own analytics software to supplement any software to streamline and shorten the review time.</p>
                                </motion.div>
                            <motion.div className='pt-5 flex' 
                             initial="hidden"
                             whileInView="visible"
                             viewport={{ once: true, amount: 0.7 }}
                             transition={{ delay: 0.7, duration: 0.7 }}              
                             variants={{
                             hidden: { opacity: 0, y: 50 },
                             visible: { opacity: 1, y: 0 },
                             }}>
                                <img src="check-mark.png" alt="" className='w-[65px]'/>
                                <p className='pt-5 font-semibold'>Global team.</p>
                            </motion.div>

                                <motion.div 
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.7 }}
                                transition={{ delay: 0.9, duration: 0.7 }}              
                                variants={{
                                hidden: { opacity: 0, y: 50 },
                                visible: { opacity: 1, y: 0 },
                                }}>
                                    <p>
                                    Our expertise in regulatory investigations and litigation matters comes from our teams of experts in mergers and acquisitions, 
                                    investigations and economics. We have a senior team of authorities in Second Request law and procedure, technology experts, and 
                                    multilingual reviewers based around the globe. Both our U.S. and non-U.S. jurisdiction services include non-English language 
                                    review and navigation of local regulations and customs. We have established relationships with relevant regulatory bodies built 
                                    from the sheer number of projects and successful outcomes we’ve managed for clients. Our commitment to collaboration, visibility 
                                    and superior communication with all parties involved, combined with our nuanced understanding of each step of the regulatory 
                                    process, makes our team unrivaled in the space.
                                    </p>
                                </motion.div>
                             
                        </div>
                            </div>
                        </div>

                        <div className='bg-[ink] h-fit'>
                        
                                <div className='pt-8'>
                                    <img src="https://finicalholdings.com/wp-content/uploads/2020/08/smilling-lady-working-as-a-customer-service-agent-in-credit-card-processing-company.jpg" alt="" />
                                </div>
                                <Link to="/Contact"><button className='mt-5 Con__btn'>Contact us</button></Link>
                        </div>
                       
                </div>
            </div>

            <div className='bg-[#E6F4FC] pb-10 pt-5 pl-3 pr-3' id='Review'>
        <div className='mx-auto overflow-hidden md:max-w-6xl'>
            <Swiper/>   
        </div>
        </div>

        <Footer/>
        </div> )}
        </div>
    </>
  )
}

export default Help