import React from 'react'
import Navbar from './Navbar'
 import AimatedText from './AnimatedText'
import { motion } from 'framer-motion'
import Swiper from './Swiper'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import Footer from './Footer'
import { Link } from 'react-router-dom'

const Home = () => {
  return (
    <>
        <Navbar/>
        <div>
            <div className=' mx-auto  overflow-hidden md:max-w-7xl pt-[17%] xl:pt-[3%] lg:pt-[3%] md:pt-[10%] sm:pt-[17%]'>
                <div className='grid ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 mt-[5%]'>
                    <div className='bg-[reen] h-fit '>
                            <motion.div className='flex justify-center xl:flex xl:justify-start'
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.1, duration: 0.6 }}
                            variants={{
                            hidden: { opacity: 0, scale: 0.8 },
                            visible: { opacity: 1, scale: 1 },
                            }}>
                                <img src="seppdy.png" alt="" 
                                className='w-[70%] md:w-[50%] xl:w-[70%] '/>
                            </motion.div>
                        
                    </div>
                    <div className='bg-[reen] h-fit'>
                            <motion.div className='bg-[#1c7fbb] w-[100%] h-fit'
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.7 }}
                            transition={{ delay: 0.3, duration: 0.7 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                <p className='text-[1.2rem] pl-3 pr-2 text-white font-sans font-semibold pt-5 pb-5'>
                                Are you seeking comprehensive assistance in your endeavor to reclaim funds from fraudulent 
                                entities and effectively track and monitor illicit activities involving cryptocurrencies?
                                </p>
                            </motion.div>

                            <div className='pt-4'
                           >
                                <motion.p  initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.7 }}
                            transition={{ delay: 0.3, duration: 0.7 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                ClassicRetrieval comprises a distinguished team of adept and credentialed investigators, proficient in 
                                the domain of blockchain and cryptocurrency, with a specific focus on the recovery of funds from scammers. 
                                </motion.p>
                                    <br/> <br/>
                                <motion.p 
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.7 }}
                                 transition={{ delay: 0.5, duration: 0.7 }}              
                                 variants={{
                                 hidden: { opacity: 0, y: 50 },
                                 visible: { opacity: 1, y: 0 },
                                 }}>With an unwavering commitment to combating fraudulent activities,ClassicRetrieval diligently investigates and 
                                tracks cryptocurrency transactions associated with scams. Our team specializes in leveraging advanced blockchain 
                                forensic techniques to uncover vital evidence, enabling the restoration of funds to their rightful owners.
                                </motion.p>
                            </div>
                    </div>
                </div>
          </div>

          <motion.div className='  overflow-hidden md:max-w-2xl'
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.7 }}
          transition={{ delay: 0.2, duration: 0.7 }}              
          variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
          }}>
            <div className='pl-3 pt-5'>
                <p className='text-[1.4rem] xl:text-[1.8rem] lg:text-[1.3rem] font-bold font-sans text-center xl:text-start 
                md:text-start lg:text-start'>Have you lost money to unregulated brokers?</p>

                <p className='pt-4'>ClassicRetrieval works closely with law enforcement and law firms globally and is frequently 
                    called upon to offer expert testimony in court and arbitration proceedings</p>
            </div>
            <div className='fle justify-center mb-[10%] pl-5'>
               <Link to="/Contact"><button className='mt-5 Con__btn'>Contact Us</button></Link>
            </div>
          </motion.div>

          <div className='bg-[#e6f4fc] h-fit'>
                <div className='pt-5 mx-auto  overflow-hidden md:max-w-7xl'>
                <AimatedText text="Ways We Help" className='text-[2rem] xl:text-[3rem] text-[balck] text-center'/>
                    <div className='grid ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 mt-[5%]'>
                        <div className='bg-[ndigo] h-fit'>
                            <motion.p className='pl-2 text-[1.5rem] font-sans font-semibold'
                             initial="hidden"
                             whileInView="visible"
                             viewport={{ once: true, amount: 0.7 }}
                             transition={{ delay: 0.2, duration: 0.7 }}              
                             variants={{
                             hidden: { opacity: 0, y: 50 },
                             visible: { opacity: 1, y: 0 },
                             }}>
                                Certain investigations require the in-depth expertise and experience our Forensic Services Division provides.
                            </motion.p>

                            <motion.p className='pl-2 pt-5'
                             initial="hidden"
                             whileInView="visible"
                             viewport={{ once: true, amount: 0.7 }}
                             transition={{ delay: 0.4, duration: 0.7 }}              
                             variants={{
                             hidden: { opacity: 0, y: 50 },
                             visible: { opacity: 1, y: 0 },
                             }}>
                            We tirelessly raise awareness, expose fraudsters, and recover frozen assets for creditors. Through liquidations 
                            and international freezing orders, we regain control of funds held by banks. Our unwavering commitment ensures 
                            the defeat of fraudsters.
                            </motion.p>

                            <motion.div className='pt-5 flex' 
                             initial="hidden"
                             whileInView="visible"
                             viewport={{ once: true, amount: 0.7 }}
                             transition={{ delay: 0.5, duration: 0.7 }}              
                             variants={{
                             hidden: { opacity: 0, y: 50 },
                             visible: { opacity: 1, y: 0 },
                             }}>
                                <img src="check-mark.png" alt="" className='w-[65px]'/>
                                <p className='pt-5'>Quick and simple review of your case</p>
                            </motion.div>
                            <motion.div className='pt-5 flex' 
                             initial="hidden"
                             whileInView="visible"
                             viewport={{ once: true, amount: 0.7 }}
                             transition={{ delay: 0.6, duration: 0.7 }}              
                             variants={{
                             hidden: { opacity: 0, y: 50 },
                             visible: { opacity: 1, y: 0 },
                             }}>
                                <img src="check-mark.png" alt="" className='w-[65px]'/>
                                <p className='pt-5'>Outsourcing to manage overflow</p>
                            </motion.div>
                            <motion.div className='pt-5 flex' 
                             initial="hidden"
                             whileInView="visible"
                             viewport={{ once: true, amount: 0.7 }}
                             transition={{ delay: 0.7, duration: 0.7 }}              
                             variants={{
                             hidden: { opacity: 0, y: 50 },
                             visible: { opacity: 1, y: 0 },
                             }}>
                                <img src="check-mark.png" alt="" className='w-[65px]'/>
                                <p className='pt-5'>Support for ongoing investigations</p>
                            </motion.div>

                            <motion.div className='flex justify-center' 
                             initial="hidden"
                             whileInView="visible"
                             viewport={{ once: true, amount: 0.7 }}
                             transition={{ delay: 0.8, duration: 0.7 }}              
                             variants={{
                             hidden: { opacity: 0, y: 50 },
                             visible: { opacity: 1, y: 0 },
                             }}>
                              <Link to="/Help"><button className='mt-5 Con__btn'>Read More</button></Link>
                            </motion.div>
                        </div>

                        <div className='bg-[ndigo] h-fit'>
                            <div className='flex justify-center '>
                                <img src='worker.jpg'
                                alt=''/>
                            </div>
                        </div>
                    </div>
                </div>
          </div>

          <div className=' mx-auto  overflow-hidden md:max-w-7xl'>
            <div className='grid ml-3 mr-2 grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 gap-1 pb-10 mt-[2%]'>
                 <motion.div className='h-full border__x' 
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.7 }}
                  transition={{ delay: 0.1, duration: 0.5 }}              
                  variants={{
                  hidden: { opacity: 0, y: 50 },
                  visible: { opacity: 1, y: 0 },
                  }}>
                        <div className='flex justify-center pt-2'>
                            <img src="trend.png" alt="" className='w-[70px]'/>
                        </div>
                        <div className='flex justify-center'>
                            <p className='text-[1.2rem] font-semibold'>Cryptocurrency</p>
                        </div>
                        <div>
                             <p className='pl-3 pr-2 text-center pt-4 pb-5'>
                             Amidst the lockdown, we achieved remarkable success by recovering significant funds from cryptocurrency scams. 
                             Reporting record-breaking incidents, our unwavering dedication during challenging times brings hope to victims, 
                             providing a beacon of justice in the face of fraud.
                             </p>
                        </div>
                 </motion.div>

                 <motion.div className='bg-[reen] h-fit border__x' 
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.7 }}
                  transition={{ delay: 0.2, duration: 0.5 }}              
                  variants={{
                  hidden: { opacity: 0, y: 50 },
                  visible: { opacity: 1, y: 0 },
                  }}>
                        <div className='flex justify-center pt-2'>
                            <img src="forex.png" alt="" className='w-[70px]'/>
                        </div>
                        <div className='flex justify-center'>
                            <p className='text-[1.2rem] font-semibold'>Forex Trading</p>
                        </div>
                        <div>
                             <p className='pl-3 pr-2 text-center pt-4 pb-5'>
                             In close partnership with the CFTC, we specialize in uncovering and addressing forex trading fraud perpetuated 
                             by brokers and individual hedge fund actors. With a successful track record in fund recovery, we relentlessly 
                             pursue justice, unmasking complex schemes and safeguarding the interests of our clients.
                             </p>
                        </div>
                 </motion.div>
                 <motion.div className='bg-[reen] h-full border__x' 
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.7 }}
                  transition={{ delay: 0.3, duration: 0.5 }}              
                  variants={{
                  hidden: { opacity: 0, y: 50 },
                  visible: { opacity: 5, y: 0 },
                  }}>
                 <div className='flex justify-center pt-2'>
                            <img src="broken-heart.png" alt="" className='w-[80px]'/>
                        </div>
                        <div className='flex justify-center'>
                            <p className='text-[1.2rem] font-semibold text-center'>Romance Scam Recovery</p>
                        </div>
                        <div>
                             <p className='pl-3 pr-2 text-center pt-4 pb-8'>
                             For years, we have diligently restored funds to victims of romance scammers. Our unwavering commitment to 
                             seeking justice and providing solace has resulted in successful financial recoveries, offering hope to those 
                             affected by these fraudulent acts.
                             </p>
                        </div>
                 </motion.div>
            </div>
          </div>

            <div className='bg-[#e6f4fc] h-fit'>
                <div className='mx-auto  overflow-hidden md:max-w-6xl'>
                    <div>
                        <AimatedText text="Our Service" className='text-[2rem] xl:text-[3rem] text-[balck] text-center'/>
                    </div>

                    <div>
                        <p className='text-center pt-4 pl-2 pr-1'>
                            At our core, we pride ourselves on delivering unparalleled services that provide optimal solutions to meet your 
                            needs. With a blend of expertise, innovation, and a customer-centric approach, we are able to consistently offer 
                            the finest solutions available in the market. Our commitment to excellence, coupled with our deep understanding 
                            of industry trends and emerging technologies, empowers us to exceed expectations and deliver results that truly 
                            make a difference.
                        </p>
                    </div>
                </div>

                <div className='mx-auto  overflow-hidden md:max-w-7xl'>
                    <div className='grid ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-2 gap-5 pb-5 mt-[5%]'>
                            <motion.div className='bg-[een] h-full border__x overflow-hidden pb-5'
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.2, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                <div className='pt-5'>
                                    <img src="blackmail.png" alt="" className='w-[70px]'/>
                                </div>

                                <div className='pl-5 pr-2'>
                                    <p className='text-[1.3rem] font-semibold'>Blackmail/Extortion</p>

                                    <p className=''>We stand by victims of sextortion, blackmail, extortion, romance scams, and financial loss. 
                                        With unwavering dedication, we assist in recovery and provide support to restore security 
                                        and peace of mind. Our empathetic approach and relentless pursuit of justice aim to 
                                        alleviate the impact of these malicious acts.</p>
                                </div>

                                <div className='flex justify-end mr-5 pt-5'>
                                 <Link to="/Blackmail"><button className='LearnMorex'>Learn More <i class="ri-arrow-right-line"></i></button></Link>
                                </div>
                            </motion.div>
                            <motion.div className='bg-[reen] h-full border__x'
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.4, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                <div className='pt-5 pl-5'>
                                    <img src="trend.png" alt="" className='w-[70px]'/>
                                </div>

                                <div className='pl-5 pr-2'>
                                    <p className='text-[1.3rem] font-semibold'>Crypto Currency Recovery</p>

                                    <p className='pb-2'>We excel in cryptocurrency recovery, including Bitcoin, Ethereum, USDT, and hacked Bitcoin accounts. 
                                    Our expert team employs advanced techniques to help you regain access to your valuable digital assets. Trust us for 
                                    comprehensive solutions and personalized support in restoring your cryptocurrencies.</p>
                                </div>

                                <div className='flex justify-end mr-5 pt-5 pb-5'>
                                  <Link to="/CryptoRestore"><button className='LearnMorex'>Learn More <i class="ri-arrow-right-line"></i></button></Link>  
                                </div>
                            </motion.div>
                            <motion.div className='bg-[een] h-fit border__x' 
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.6, duration: 0.5 }}              
                                 variants={{
                                 hidden: { opacity: 0, y: 50 },
                                 visible: { opacity: 1, y: 0 },
                                 }}>
                            
                                <div className='pt-5 pl-5'>
                                    <img src=" Switch-on.png" alt="" className='w-[70px]'/>
                                </div>

                                <div className='pl-5 pr-2'>
                                    <p className='text-[1.3rem] font-semibold'>Account Activation</p>

                                    <p className='pb-1'>
                                    We specialize in reactivating deactivated accounts on popular platforms like Instacart, Amazon Flex, DoorDash, Uber Eats, 
                                    Shipt, GrubHub, and more. Regain access to these essential services effortlessly and resume your operations seamlessly 
                                    with our expert assistance, we ensure a smooth and efficient reactivation process.
                                    </p>
                                </div>

                                <div className='flex justify-end mr-5 pt-5 pb-5'>
                                  <Link to="/Activation"><button className='LearnMorex'>Learn More <i class="ri-arrow-right-line"></i></button></Link>  
                                </div>
                            
                            </motion.div>
                    </div>

                    <div className='grid ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 pb-10 mt-[%]'>
                            <motion.div className='bg-[reen] h-fit border__x'
                             initial="hidden"
                             whileInView="visible"
                             viewport={{ once: true, amount: 0.5 }}
                             transition={{ delay: 0.8, duration: 0.5 }}              
                             variants={{
                             hidden: { opacity: 0, y: 50 },
                             visible: { opacity: 1, y: 0 },
                             }}>
                                <div className='pt-5 pl-5'>
                                    <img src=" broken-heart.png" alt="" className='w-[70px]'/>
                                </div>

                                <div className='pl-5 pr-2'>
                                    <p className='text-[1.3rem] font-semibold'>Romance Scam Recovery</p>

                                    <p className='pb-1'>
                                    We specialize in supporting victims of romance scams, offering comprehensive solutions to 
                                    help you recover from the devastating effects of these fraudulent acts. Our dedicated team 
                                    is committed to restoring your confidence and guiding you through the process of recovering 
                                    your stolen assets. With our unwavering support, you can find solace and take steps towards 
                                    healing and reclaiming what is rightfully yours.
                                    </p>
                                </div>

                                <div className='flex justify-end mr-5 pt-5 pb-5'>
                                 <Link to="/RomanceScamRestore"><button className='LearnMorex'>Learn More <i class="ri-arrow-right-line"></i></button></Link>
                                </div>
                            </motion.div>
                            <motion.div className='bg-[reen] h-fit border__x'
                             initial="hidden"
                             whileInView="visible"
                             viewport={{ once: true, amount: 0.5 }}
                             transition={{ delay: 0.9, duration: 0.5 }}              
                             variants={{
                             hidden: { opacity: 0, y: 50 },
                             visible: { opacity: 1, y: 0 },
                             }}>
                                <div className='pt-5 pl-5'>
                                    <img src="bullhorn.png" alt="" className='w-[70px]'/>
                                </div>

                                <div className='pl-5 pr-2'>
                                    <p className='text-[1.3rem] font-semibold'>Social Media Hack/Recovery</p>

                                    <p className='pb-'>
                                    We specialize in account recovery services for various platforms, including Facebook, Instagram, Twitter, TikTok, 
                                    Snapchat, and banking applications. If you've lost access to your accounts or encountered security issues, our 
                                    dedicated team is here to assist you. With our expertise and proven methods, we can help you regain control of 
                                    your accounts and ensure their security. Count on us to provide reliable and efficient solutions for all your 
                                    account recovery needs.
                                    </p>
                                </div>

                                <div className='flex justify-end mr-5 pt-5 pb-5'>
                                   <Link to="/SocialMedia"><button className='LearnMorex'>Learn More <i class="ri-arrow-right-line"></i></button></Link>
                                </div>
                            </motion.div>
                            
                    </div>
                </div>

               
            </div>

            <div className='mx-auto  overflow-hidden md:max-w-6xl'>
                    <div>
                        <AimatedText text="About Us" className='text-[2rem] xl:text-[3rem] text-[balck] text-center'/>
                    </div>

                    <div>
                        <p className='text-center pt-4 pl-2 pr-1'>
                        As a leading protection and recovery firm, we specialize in assisting victims in regaining access to their lost or 
                        disabled accounts and recovering lost funds from scammers. Our dedicated team of experts is committed to providing 
                        comprehensive solutions tailored to your specific situation. Whether you've been locked out of your accounts or have 
                        fallen victim to financial scams, we are here to guide you through the process of restoring access and recovering your 
                        hard-earned money. Trust us to be your steadfast ally in seeking justice and reclaiming what is rightfully yours.
                        </p>
                    </div>

                    <div className='flex justify-center pb-10' >
                      <Link to="/About"><button className='mt-5 Con__btn'>See More</button></Link>
                    </div>
            </div>

             <div className='bg-[#e6f4fc] h-fit'>
                <div className='flex justify-center pt-10'>
                    <p className='font-semibold text-[#1C7FBB]'>“What People say about us“</p>
                </div>
                <AimatedText text="Testimonials" className='text-[2rem] xl:text-[3rem] text-[balck] text-center'/>
            

             <div className=" mx-auto  overflow-hidden md:max-w-7xl" id='Review'>
                        <div className="grid ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-10">
                            <div className="bg-[] h-fit">
                                <div className="flex justify-center">
                                    <img src="https://www.marketingdonut.co.uk/sites/default/files/ten_ways_to_get_the_most_out_of_customer_testimonials.jpg" alt="" 
                                    className="rounded-[10px]"/>
                                </div>

                                <div className="">
                                    <motion.div className="bg-[black] h-fit w-[50%] relative bottom-[13rem] left-10 rounded-[10px] 
                                    overflow-hidden Border__x pb-5"
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true, amount: 0.7 }}
                                    transition={{ delay: 0.3, duration: 0.7 }}              
                                    variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                    }}>
                                       
                                       <div className="flex justify-center pt-5">
                                            <p className="text-[#b4b3b3] text-[1.2rem] pl-2 text-center">4.6K+Reviews and still Counting</p>
                                       </div>
                                       <div className="flex justify-center pt-1 pl-3">
                                       {/* <Rating name="read-only" value={value} readOnly /> */}
                                       </div>
                                    </motion.div>
                                </div>
                            </div>

                            <div className="bg-[ink] h-fit overflow-hidden">
                                <div className="overflow-hidden">
                                        {/* <p className="text-[1.5rem] font-medium text-white text-center">Discover What They Say About Us</p> */}
                                </div>
                                <Swiper/>
                            </div>
                        </div>
                     </div>
                </div>

                <div className='flex justify-center pt-10'>
                    <p className='font-semibold text-[#1C7FBB]'>Frequently Asked Questions</p>
                </div>
                <AimatedText text="F .A. Q" className='text-[2rem] xl:text-[3rem] text-[balck] text-center'/>
                <div className='bg-[#E6F4FC]'>
                <div className="mx-auto overflow-hidden md:max-w-7xl mt-[5%] pb-[5%]">
                    <div className=" grid  grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-1">
                        <div className='bg-[reen] h-fit'>

                            <div className='pt-0 xl:pt-10 lg:pt-10'>
                            <Accordion className="">
                                <AccordionSummary expandIcon={<i class="ri-arrow-down-s-line text-[1.4rem] text-[#1C7FBB]"></i>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                    <p className="text-[black] font-semibold">Can I Trust this Company?</p>
                                </AccordionSummary>
                                <AccordionDetails>
                                Rest assured, as a trusted and reliable entity with a proven track record, we specialize in safeguarding your privacy. 
                                With the unwavering confidence of over 2,000 clients, we have earned our reputation as a leader in maintaining the 
                                utmost confidentiality. Our commitment to privacy and our track record of reliability speak volumes about the 
                                trustworthiness of our services. When you choose to partner with us, you can rest easy knowing that your privacy is in 
                                capable hands.
                                </AccordionDetails>
                            </Accordion>    
                            </div>      

                            <div>
                            <Accordion className="">
                                <AccordionSummary expandIcon={<i class="ri-arrow-down-s-line text-[1.4rem] text-[#1C7FBB]"></i>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                    <p className="text-[black] font-semibold">Refund Policy</p>
                                </AccordionSummary>
                                <AccordionDetails>
                                We understand that circumstances may change, and we want to assure our valued audience that we have a flexible refund policy in place. 
                                If, for any reason, you wish to back out or reconsider your decision, we are committed to providing a seamless refund process. Your 
                                satisfaction and peace of mind are of utmost importance to us, and we want you to feel confident and empowered in your interactions 
                                with our services. You can rely on our customer-centric approach, which ensures that your refund requests will be handled promptly 
                                and efficiently, respecting your needs and preferences.
                                </AccordionDetails>
                            </Accordion>    
                            </div>
                        </div>

                        <div className='bg-[reen] h-fit'>
                        <div className="pt-0 xl:pt-10 lg:pt-10">
                            <Accordion className="">
                                <AccordionSummary expandIcon={<i class="ri-arrow-down-s-line text-[1.4rem] text-[#1c7fbb]"></i>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                    <p className="text-[black] font-semibold">How dose Recovery Work?</p>
                                </AccordionSummary>
                                <AccordionDetails>
                                At ClassicRetrieval, we possess an intricate comprehension that every account recovery endeavor necessitates a 
                                bespoke approach, considering the distinctive intricacies inherent to each case. Employing a multifaceted 
                                strategy, we employ a diverse array of techniques tailored to the unique circumstances at hand. <br /> <br/>

                                In instances involving password recovery, we engage in close collaboration with our esteemed clients, 
                                diligently extracting encrypted private key backups and meticulously constructing an exhaustive repertoire 
                                of potential password conjectures. Leveraging cutting-edge algorithms and sophisticated software, we embark 
                                upon the arduous task of scrutinizing billions, or even trillions, of conceivable password permutations until 
                                the elusive correct password is unearthed. <br /> <br />

                                By embracing such advanced methodologies and employing state-of-the-art technological innovations, we endeavor to navigate 
                                the intricacies of account recovery with utmost precision and efficacy. Rest assured, at ClassicRetrieval, we leave no stone 
                                unturned in our relentless pursuit to reclaim your valuable accounts.
                                </AccordionDetails>
                            </Accordion>    
                            </div>

                            <div>
                            <Accordion className="">
                                <AccordionSummary expandIcon={<i class="ri-arrow-down-s-line text-[1.4rem] text-[#1C7FBB]"></i>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                    <p className="text-[black] font-semibold">How long does the process take?</p>
                                </AccordionSummary>
                                <AccordionDetails>
                                The intricate process of reclaiming lost cryptocurrency exhibits a varying duration, spanning from expeditious 
                                resolutions within a mere hour to more protracted endeavors extending up to two or more months. While this 
                                temporal span may appear extensive, it derives from our steadfast commitment to executing a meticulous and 
                                exhaustive investigation during the initial week of client onboarding. <br /> 

                                Having diligently combed through the available resources during this preliminary phase, we securely retain the encrypted private 
                                backups and password conjectures. This enables us to sustain our testing endeavors, continually probing for new recovery vectors 
                                and augmenting our hardware capabilities as we forge ahead. <br /> <br />
                                At ClassicRetrieval, we hold our clients' privacy and security paramount. Consequently, we judiciously preserve client information unless 
                                expressly requested otherwise, adhering to stringent protocols that underscore our unwavering commitment to protecting their 
                                confidentiality.
                                </AccordionDetails>
                            </Accordion>    
                            </div>
                        </div>
                    </div>
                </div>
              
                </div>

            <Footer/>
        </div>
    </>
  )
}

export default Home